export default defineNuxtRouteMiddleware((from, to) => {
  const config = useRuntimeConfig();
  const nuxtApp = useNuxtApp()
  
  if (!config.public.maintenance_mode) {
    return;
  }

  useHandleError({
    status: 503,
    message: nuxtApp.$i18n.t("The website is under maintenance. We'll be back shortly."),
  });
});