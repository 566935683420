import revive_payload_client_T0R1IllBJm from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gVeTMvsO0C from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4HjIK95A2Q from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_gywDEF9TrR from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@20.17.1_terser@5_etxzccfhbujoq4ev5qxzzrsgne/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_6Dkotjo7sp from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZLYOg9HxJ8 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JiJqqv97MY from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TMYluPSMfP from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BS57ajO0YB from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.2_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_j4QcUlKKIq from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.17.1_eslint@8.57.1_ioredis@5.4.1_magicast@0._2juwxjyf5w2kppdwjkfigyayie/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_u07u2BRH2C from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_7tmvzplv2afk74x5iqljzw6xda/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/apps/nuxt/.nuxt/formkitPlugin.mjs";
import switch_locale_path_ssr_m9GXAZ4hFm from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.2_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_3zoZSIZ1fz from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.2_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_T0R1IllBJm,
  unhead_gVeTMvsO0C,
  router_4HjIK95A2Q,
  _0_siteConfig_gywDEF9TrR,
  payload_client_6Dkotjo7sp,
  navigation_repaint_client_ZLYOg9HxJ8,
  check_outdated_build_client_JiJqqv97MY,
  chunk_reload_client_TMYluPSMfP,
  plugin_vue3_BS57ajO0YB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_j4QcUlKKIq,
  plugin_u07u2BRH2C,
  formkitPlugin_pZqjah0RUG,
  switch_locale_path_ssr_m9GXAZ4hFm,
  i18n_3zoZSIZ1fz
]