import '@formkit/themes/genesis';
import { fr, en, it, es, de, pl } from '@formkit/i18n';
import { defineFormKitConfig } from '@formkit/vue';
import { genesisIcons } from '@formkit/icons';
import { generateClasses } from '@formkit/themes';

export default defineFormKitConfig({
  locales: { fr, en, it, es, de, pl },
  locale: 'en',
  icons: {
    ...genesisIcons,
  },
  config: {
    classes: generateClasses({
      global: {
        // all 
        wrapper: '',
        help: 'text-gray-400 text-xs mt-1',
        inner: '$reset',
      },
      text: {
        inner: '$reset',
        input: '$reset form-input',
      },
      email: {
        inner: '$reset',
        input: '$reset form-input',
      },
      textarea: {
        wrapper: '$reset w-full',
        inner: '$reset',
        input: '$reset form-textarea border border-gray-200 shadow-sm rounded focus',
      },
      password: {
        inner: '$reset',
        input: '$reset form-input',
      },
      submit: {
        inner: '$reset',
        input: '$reset btn btn-primary',
      },
      checkbox: {
        'icon': '$reset hidden',
        input: '$reset form-checkbox',
        label: '$reset text-sm pl-2',
      },
      select: {
        inner: '$reset',
        'icon': '$reset hidden',
        input: '$reset form-select',
      },
    }),
  },
});
