import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (_routes) => {
    let routes = (lang: string) => {
      let routes: any[] = [
        {
          name: `home___${lang}`,
          path: `/${lang}`,
          component: () => import('~/pages/home.vue').then((r) => r.default || r),
        },
        {
          name: `error___${lang}`,
          path: `/${lang}/error`,
          component: () => import('~/pages/error.vue').then((r) => r.default || r),
        },
        {
          name: `search___${lang}`,
          path: `/${lang}/search`,
          component: () => import('~/pages/search.vue').then((r) => r.default || r),
        },
        {
          name: `login___${lang}`,
          path: `/${lang}/login`,
          component: () => import('~/pages/auth/login.vue').then((r) => r.default || r),
        },
        {
          name: `register___${lang}`,
          path: `/${lang}/register`,
          component: () => import('~/pages/auth/register.vue').then((r) => r.default || r),
        },
        {
          name: `forgot-password___${lang}`,
          path: `/${lang}/forgot-password`,
          component: () => import('~/pages/auth/reset-password.vue').then((r) => r.default || r),
        },
        {
          name: `reset-password___${lang}`,
          path: `/${lang}/reset-password`,
          component: () => import('~/pages/auth/set-new-password.vue').then((r) => r.default || r),
        },
        {
          name: `my-account___${lang}`,
          sensitive: true,
          path: `/${lang}/my-account`,
          meta: { middleware: ['auth'] },
          component: () => import('~/pages/my-account/index.vue').then((r) => r.default || r),
        },
        {
          name: `cart___${lang}`,
          path: `/${lang}/cart`,
          component: () => import('~/pages/cart.vue').then((r) => r.default || r),
        },
        {
          name: `my-account-addresses___${lang}`,
          path: `/${lang}/my-account/addresses`,
          meta: { middleware: ['auth'] },
          component: () => import('~/pages/my-account/addresses.vue').then((r) => r.default || r),
        },
        {
          name: `my-account-orders___${lang}`,
          path: `/${lang}/my-account/orders`,
          meta: { middleware: ['auth'] },
          component: () => import('~/pages/my-account/orders.vue').then((r) => r.default || r),
        },
        {
          name: `checkout___${lang}`,
          path: `/${lang}/checkout`,
          component: () => import('~/pages/checkout/index.vue').then((r) => r.default || r),
        },
        {
          name: `checkout-success___${lang}`,
          path: `/${lang}/checkout/success`,
          component: () => import('~/pages/checkout/success.vue').then((r) => r.default || r),
        },
        {
          name: `checkout-failed___${lang}`,
          path: `/${lang}/checkout/failed`,
          component: () => import('~/pages/checkout/failed.vue').then((r) => r.default || r),
        },
        {
          name: `checkout-validate___${lang}`,
          path: `/${lang}/checkout/validate`,
          component: () => import('~/pages/checkout/validate.vue').then((r) => r.default || r),
        },
        {
          name: `product___${lang}`,
          path: `/${lang}/:id([a-zA-Z0-9-]+).html`,
          component: () => import('~/pages/product.vue').then((r) => r.default || r),
        },
      ];

      const config = useRuntimeConfig();
      if (config.public.app_theme == 'blue') {
        routes = routes.concat([
          {
            name: `brands___${lang}`,
            path: `/${lang}/brands`,
            component: () => import('~/pages/brands/index.vue').then((r) => r.default || r),
          },
          {
            name: `brand___${lang}`,
            path: `/${lang}/brands/:id([0-9-]+-[a-zA-Z0-9-]+)`,
            component: () => import('~/pages/brands/show.vue').then((r) => r.default || r),
          },
        ]);
      }

      routes = routes.concat([
        {
          name: `category___${lang}`,
          path: `/${lang}/:id([0-9-]+-[a-zA-Z0-9-]+)`,
          component: () => import('~/pages/category.vue').then((r) => r.default || r),
          sensitive: true,
        },
        {
          name: `page___${lang}`,
          path: `/${lang}/:slug`,
          component: () => import('~/pages/page.vue').then((r) => r.default || r),
        },
      ]);

      return routes;
    };

    return ['en', 'fr', 'it', 'es', 'de'].flatMap((lang) => routes(lang));
  },
};
