<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
  props: {
    href: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  setup() {
    return {
      NuxtLink: resolveComponent('NuxtLink'),
    }
  }
});
</script>

<template>
  <component :is="href ? NuxtLink : 'button'" :to="href" v-bind="$attrs" class="btn" :class="{
    'btn-primary': variant === 'primary',
    'btn-secondary': variant === 'secondary',
  }">
    <slot />
  </component>
</template>
