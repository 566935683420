import { useSdk } from "~/sdk";

export default async function () {
  if (!import.meta.server) return;

  const headers = useRequestHeaders();
  const cookieId = useCookie('geolocation_id');
  const cookieCode = useCookie('geolocation_code');
  const countryCookie = useCookie('vsf-country');
  const localeCookie = useCookie('vsf-locale');

  if (headers['cf-ipcountry']) {
    const data = await useSdk().commerce.getCountry({ code: headers['cf-ipcountry'] });
    cookieId.value = data?.id
    cookieCode.value = data?.code

    if (!countryCookie.value || countryCookie.value.length === 0) {
      countryCookie.value = data?.id
    }
  }

  if (headers['accept-language']) {
    localeCookie.value = headers['accept-language'].slice(0, 2)
  }
}